import React, { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { useCookies } from "react-cookie";
import styled from "styled-components";
import { GridColumn, Label, Footnote, LoaderInline } from "notes";
import {
  PhoneMask,
  firebase,
  MessageContext,
  FieldMessage,
  EventContext,
} from "Components";

export const TextReminder = ({ showLabel, buttonText }) => {
  const { event } = useContext(EventContext);
  const [cookies, setCookie] = useCookies();

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const defaultNumber = urlParams.get("phone");

  let userToken = urlParams.get("token");
  if (!userToken) userToken = cookies.token;

  const [loading, setLoading] = useState(false);
  const { displayMessage } = useContext(MessageContext);
  const [phoneValue, setPhoneValue] = useState(defaultNumber);
  const [error, setError] = useState("");

  const history = useHistory();
  const confirmationMsg =
    "Your reminder and link will be sent on the day of the show!";

  const handleSubmit = async () => {
    if (!!phoneValue && phoneValue.length >= 10) {
      setLoading(true);
      try {
        const authRemind = firebase.functions().httpsCallable("auth-remind");
        authRemind({
          token: userToken,
          phone: phoneValue,
          eventId: process.env.REACT_APP_EVENT_ID,
        }).then(({ data }) => {
          if (data.success) {
            setLoading(false);
            setCookie("reminderSent", true, { maxAge: 31622400 });
            history.push(
              `/?token=${encodeURIComponent(decodeURIComponent(userToken))}`
            );
            displayMessage({
              color: "green",
              children: confirmationMsg,
              timeout: 20000,
            });
          } else {
            setLoading(false);
            setError("There was an issue with your request. Please try again.");
          }
        });
      } catch (error) {
        setLoading(false);
        setError(error.message);
      }
    } else {
      setError("You must enter a valid phone number to receive a reminder.");
    }
  };

  return (
    <GridColumn>
      {cookies?.reminderSent ? (
        <StyledLabel style={{ color: "#cc8e3c", textAlign: "center" }}>
          {confirmationMsg}
        </StyledLabel>
      ) : (
        <>
          <StyledLabel>Text Reminder</StyledLabel>
          <StyledPhone
            phoneNumber={phoneValue}
            onChange={(value) => {
              setPhoneValue(value);
              setError("");
            }}
            error={!!error}
            placeholder="Phone Number"
          />
          <FieldMessage active={!!error} content={error} />
          <StyledFootnote>
            <span>
              Text me a reminder 30 minutes before the show with my ticket link.
            </span>{" "}
            Message and data rates may apply. Consent is not a condition of
            purchase. Text back STOP to cancel.
          </StyledFootnote>
          <Button disabled={loading} onClick={handleSubmit}>
            {loading ? (
              <LoaderInline />
            ) : buttonText ? (
              buttonText
            ) : (
              "Text me a reminder!"
            )}
          </Button>
        </>
      )}
    </GridColumn>
  );
};

const StyledLabel = styled(Label)`
  color: #d6d6d6;
  font-family: "Arimo", sans-serif;
  font-size: 15px;
  font-weight: 700;
  line-height: 1.2;
  letter-spacing: 3px;
  margin-bottom: 12px;
  text-transform: uppercase;
  text-align: left;
`;

const StyledPhone = styled(PhoneMask)`
  width: 100%;
`;

const StyledFootnote = styled(Footnote)`
  margin: 12px 0 0;
  color: #b3b3b3;
  font-size: 11px;
  font-weight: 400;
  text-align: left;
  span {
    font-weight: 600;
  }
`;

const Button = styled.button`
  background-color: transparent;
  border: 2px solid #cc8e3c;
  color: #c7c7c7;
  padding: 10px 35px;
  cursor: pointer;
  text-decoration: none;
  text-transform: uppercase;
  margin-top: 24px;
  outline: 0;
  font-family: "Arimo", sans-serif;
  font-weight: 700;
  line-height: 1.2;
  letter-spacing: 1.5px;
  transition: all 0.2s ease;
  &:hover,
  &:focus {
    background-color: #cc8e3c;
    color: #000000;
  }
`;
