import React, { useState } from "react";
import styled from "styled-components";
import InputMask from "react-input-mask";
import { FlexBox } from "notes";
import { Input } from "Components";

const Container = styled(FlexBox)`
  position: relative;
`;

export const DateMask = ({ date: value, onChange, placeholder, ...props }) => {
  const [focus, setFocus] = useState(false);
  return (
    <Container>
      <InputMask
        alwaysShowMask={false}
        maskChar=""
        mask="99/99/9999"
        value={value}
        onChange={(value) => onChange(!!value.length ? value : null)}
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
      >
        {() => (
          <Input
            type="text"
            inputmode="numeric"
            data-javelin-name={`input-date`}
            data-javelin-meta={JSON.stringify(value)}
            placeholder={focus ? "" : placeholder}
            maxLength={10}
            {...props}
          />
        )}
      </InputMask>
    </Container>
  );
};
