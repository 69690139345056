import TagManager from "react-gtm-module";

const init = (data) => {
  TagManager.initialize(data);
};

const fireEvent = ({ name, ...rest }) => {
  try {
    const tagManagerArgs = {
      dataLayer: {
        event: name || "analyticsEvent",
        eventParams: {
          ...rest,
        },
      },
    };

    TagManager.dataLayer(tagManagerArgs);
  } catch (err) {
    console.log("Error firing analytics", err);
  }
};

const dataLayer = (data) => {
  const tagManagerArgs = {
    dataLayer: {
      ...data,
    },
  };

  TagManager.dataLayer(tagManagerArgs);
};

export default {
  init,
  fireEvent,
  dataLayer,
};
