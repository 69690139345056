import React, { useContext } from "react";
import styled from "styled-components";
import { Header, Heading, Main, Footer, EventContext } from "Components";
import backgroundImage from "Images/background-texture.png";

export const Layout = ({
  // header
  registration,
  showHeader,
  // hero
  heroContent,
  // heading
  heading,
  // main
  noPadding,
  subHeading,
  children,
  // site footer
  showFooter,
  ...props
}) => {
  const { event } = useContext(EventContext);

  return (
    <SiteContainer {...props}>
      {/* {event.test && <TestEventBanner>Viewing Test Event</TestEventBanner>} */}

      <Header showHeader={showHeader} registration={registration} />

      {heroContent}

      <Heading heading={heading} />

      <Main
        noPadding={noPadding}
        subHeading={subHeading}
        children={children}
        register={registration}
      />

      {/* <Footer showFooter={showFooter} registration={registration} /> */}
    </SiteContainer>
  );
};

// ======== SITE CONTAINER ======== //

const SiteContainer = styled.div`
  background-image: url(${backgroundImage});
  flex-direction: column;
  margin: 0 auto;
  /* max-width: 100%; */
  min-height: 100vh;
  position: relative;
  width: 100%;
  display: flex;

  @media ${(props) => props.theme.maxMedia.large} {
    box-shadow: 0 0 3px 0px rgba(0, 0, 0, 0.65);
  }
`;

const TestEventBanner = styled.div`
  background-color: #ff003b;
  color: #ffffff;
  font-size: 12px;
  text-align: center;
  padding: 3px 10px;
`;

// ======== VIEW TEMPLATE ======== //

export const BodySection = styled.div`
  font-size: 18px;
  color: #000000;
  padding: 25px 15px 15px;
  text-align: center;

  p {
    font-weight: 300;
    margin-top: 0;
  }
`;

export const Container = styled.div`
  max-width: 900px;
  margin: 0 auto;
  p {
    color: #b3b3b3;
    font-family: "Arimo", sans-serif;
    font-weight: 400;
    line-height: 1.2;
    font-size: 15px;
    letter-spacing: 1.5px;
  }
`;
