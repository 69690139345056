import React from "react";
import styled from "styled-components";
import analytics from "services/analytics";

export const Organization = ({ logo, organization }) => {
  const { description, button, link } = organization;
  return (
    <OrganizationWrapper>
      <OrganizationContainer>
        <OrganizationLogo src={logo} />
        <OrganizationBio>
          <OrganizationDescription>{description}</OrganizationDescription>
          <OrganizationLink
            href={link}
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => {
              analytics.fireEvent({
                category: "External Link",
                action: "Charity Organization",
                label: link,
              });
            }}
          >
            {button}
          </OrganizationLink>
        </OrganizationBio>
      </OrganizationContainer>
    </OrganizationWrapper>
  );
};

const OrganizationWrapper = styled.div`
  padding: 25px 15px 15px;
`;

const OrganizationContainer = styled.div`
  max-width: 900px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media only screen and ${(props) => props.theme.maxMedia.small} {
    flex-direction: row;
    align-items: unset;
  }
`;

const OrganizationLogo = styled.img`
  width: 100%;
  max-width: 300px;
  margin-bottom: 10px;

  @media only screen and ${(props) => props.theme.maxMedia.small} {
    margin-bottom: 0;
    margin-right: 50px;
  }
`;

const OrganizationBio = styled.div`
  font-family: "Arial";
  font-size: 18px;
  text-align: center;

  @media only screen and ${(props) => props.theme.maxMedia.small} {
    text-align: unset;
  }
`;

const OrganizationDescription = styled.p`
  margin-top: 0px;
  color: #102b4e;
`;

const OrganizationLink = styled.a`
  background-color: #4580dd;
  padding: 15px 35px;
  color: #ffffff;
  text-decoration: none;
  display: inline-block;
`;
