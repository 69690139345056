import React, { useContext } from "react";
import styled, { ThemeContext } from "styled-components";
import { GridColumn, Icon } from "notes";
import Slider from "react-slick";

function importAll(r) {
  return r.keys().map(r);
}

const images = importAll(
  require.context("Images/retailer-logos", false, /\.(png|jpe?g|svg)$/)
);

const IconPrevious = styled(Icon)`
  width: 60px;
  height: 60px;
  left: -45px;
  path {
    fill: ${(props) => props.theme.carousel.arrowFill};
  }
`;

const IconNext = styled(Icon)`
  width: 60px;
  height: 60px;
  right: -45px;
  path {
    fill: ${(props) => props.theme.carousel.arrowFill};
  }
`;

export const Carousel = () => {
  const themeContext = useContext(ThemeContext);
  const settings = {
    autoplay: themeContext.carousel?.autoplay,
    autoplaySpeed: 10000,
    dots: false,
    infinite: true,
    speed: themeContext.carousel?.speed,
    slidesToShow: themeContext.carousel?.slidesToShow[3],
    slidesToScroll: themeContext.carousel?.slidesToScroll[3],
    nextArrow: <IconNext name="Next" />,
    prevArrow: <IconPrevious name="Previous" />,
    responsive: [
      {
        breakpoint: 1060,
        settings: {
          slidesToShow: themeContext.carousel?.slidesToShow[2],
          slidesToScroll: themeContext.carousel?.slidesToScroll[2],
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: themeContext.carousel?.slidesToShow[1],
          slidesToScroll: themeContext.carousel?.slidesToScroll[1],
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: themeContext.carousel?.slidesToShow[0],
          slidesToScroll: themeContext.carousel?.slidesToScroll[0],
        },
      },
    ],
  };

  return (
    <CarouselContainer xCenter>
      <SliderContainer>
        <Slider {...settings}>
          {!!images &&
            images.sort().map((item, index) => (
              <Slide key={index}>
                <Inner>
                  <BrandImage src={item} />
                </Inner>
              </Slide>
            ))}
        </Slider>
      </SliderContainer>
    </CarouselContainer>
  );
};

const BrandImage = styled.img`
  height: auto;
  width: 100%;
  max-width: ${(props) => props.theme.carousel.imageWidth ?? "130px"};
`;

const SliderContainer = styled(GridColumn)`
  padding: 0 60px;
  width: 100%;
`;

const Slide = styled.div`
  box-sizing: border-box;
  display: flex;
  padding: 0 11px;
  min-width: 0;
  padding-top: ${(props) => props.theme.carousel.slideHeight ?? "90%"};
  position: relative;
`;

const Inner = styled.div`
  align-items: center;
  background-color: ${(props) => props.theme.carousel.slideFill};
  border-radius: 8px;
  display: flex;
  justify-content: center;
  padding: 8px;
  position: absolute;
  top: 0%;
  right: 11px;
  bottom: 0;
  left: 11px;
`;

const CarouselContainer = styled(GridColumn)`
  background: ${(props) => props.theme.carousel.background};
  padding: ${(props) => props.theme.carousel.padding[0]};
  width: 100%;

  @media only screen and ${(props) => props.theme.media.large} {
    padding: ${(props) => props.theme.carousel.padding[1]};
  }
`;
