import React from "react";
import ReactHtmlParser from "react-html-parser";
import { Video, VideoChat, Heartbeater } from "Components";
import { Layout, BodySection, Container } from "../Layout";
import { AuthAccess } from "services";

export const Show = ({ event, token }) => {
  return (
    <>
      <Layout
        // header
        showHeader
        // hero
        heroContent={
          event?.show?.chatActive ? (
            <VideoChat
              videoId={event?.show?.videoId}
              chatId={event?.show?.chatId}
              videoMeta={false}
            />
          ) : (
            <Video id={event?.show?.videoId} />
          )
        }
        videoStyle={
          event?.show?.chatActive
            ? { position: "relative", width: "100%" }
            : undefined
        }
        // heading
        heading={event?.show?.heading}
        // main
        subHeading={false}
        // noPadding
        // footer
        showFooter
      >
        <BodySection>
          <Container>
            <p>{ReactHtmlParser(event?.show?.body)}</p>
          </Container>
        </BodySection>
      </Layout>
      <AuthAccess token={token} />
      <Heartbeater />
    </>
  );
};
