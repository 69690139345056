import { useEffect } from "react";
import { firebase } from "Components";

export const AuthAccess = ({ token }) => {
  useEffect(() => {
    const interval = setInterval(() => {
      if (token) {
        const access = firebase.functions().httpsCallable("auth-access");
        access({
          token: token,
          eventId: process.env.REACT_APP_EVENT_ID,
        }).catch((err) => console.error("error", err));
      }
    }, 15000);
    return () => clearInterval(interval);
  }, []);

  return null;
};
