import React, { useContext } from "react";
import ReactHtmlParser from "react-html-parser";
import { Duration } from "luxon";
import { TimeContext, Countdown, Hero } from "Components";
import { Layout, BodySection, Container } from "../Layout";

export const PreShow = ({ event }) => {
  const { time } = useContext(TimeContext);

  const timeToStart =
    time < event?.start &&
    Duration.fromMillis(event?.start - time).shiftTo(
      "days",
      "hours",
      "minutes",
      "seconds",
      "milliseconds"
    );

  const timer = {
    label: event?.preShow?.countdownLabel ?? "Show Starts In",
    days: timeToStart.days,
    hours: timeToStart.hours,
    minutes: timeToStart.minutes,
    seconds: timeToStart.seconds,
  };

  return (
    <Layout
      // header
      showHeader
      // hero
      heroContent={<Hero heroContent={<Countdown {...timer} />} />}
      // heading
      heading={event?.preShow?.heading}
      subHeading={event.preShow?.subHeading}
      // main
      // noPadding
      // footer
      showFooter
    >
      <BodySection>
        <Container>
          <p>{ReactHtmlParser(event?.preShow?.body)}</p>
        </Container>
      </BodySection>
    </Layout>
  );
};
