import React from "react";
import { FieldMessage, Input } from "Components";

export const TextField = ({ onChange, value, field, error = {}, ...props }) => {
  const handleText = (value) => {
    onChange({ [field.name]: value });
  };

  return (
    <>
      <Input
        placeholder={field.placeholder}
        onChange={(value) => handleText(value)}
        value={value}
        error={!!error.status}
        name={field.name}
        {...props}
      />
      <FieldMessage active={!!error.status} content={error.message} />
    </>
  );
};
