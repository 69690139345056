import React, { useContext, useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import styled from "styled-components";
import ReactHtmlParser from "react-html-parser";
import { Layout } from "../Layout";
import analytics from "services/analytics";
import { Duration } from "luxon";
import {
  TimeContext,
  CountdownSmall as Countdown,
  TextReminder,
} from "Components";
import artistImage from "Images/artist-image-desktop.png";
import artistLogo from "Images/artist-logo.png";
import { RegistrationForm } from "./Components";

export const Register = React.memo(({ event, onSuccess, adminView }) => {
  const { time } = useContext(TimeContext);
  const [cookies, setCookie] = useCookies();

  const [eventFired, setEventFired] = useState(false);
  const params = new URLSearchParams(window.location.search);
  const utm_source = params.get("utm_source");

  useEffect(() => {
    if (utm_source === "oauth" && !eventFired) {
      console.log("Firing form submit");
      setEventFired(true);
      analytics.fireEvent({
        category: `Form Submit Part 1`,
        action: "Success",
        label: window.location.path,
      });
    }
  }, []);

  let RegFormContent;

  if (event.campaignEnd < time) {
    RegFormContent = (
      <>
        <Heading>{ReactHtmlParser(event.postShow.closedHeader)}</Heading>
        <p>{ReactHtmlParser(event.postShow.pastBody)}</p>
      </>
    );
  } else if (cookies?.formPart === "2" || adminView === "countdown") {
    const { time } = useContext(TimeContext);
    const { heading, countdownHeading, button } = event.register.content.part2;

    const timeToStart =
      time < event.start &&
      Duration.fromMillis(event.start - time).shiftTo(
        "days",
        "hours",
        "minutes",
        "seconds",
        "milliseconds"
      );

    const timer = {
      label: countdownHeading,
      days: timeToStart.days,
      hours: timeToStart.hours,
      minutes: timeToStart.minutes,
      seconds: timeToStart.seconds,
    };

    RegFormContent = (
      <>
        {heading && <Heading>{ReactHtmlParser(heading)}</Heading>}
        <SmallCountdown>
          <Countdown {...timer} />
        </SmallCountdown>
        <TextReminder buttonText={button ?? "Text Reminder"} />
      </>
    );
  } else {
    const { heading, subHeading, button } = event.register.content.part1;

    RegFormContent = (
      <>
        {heading && <Heading>{ReactHtmlParser(heading)}</Heading>}
        {subHeading && <Description>{ReactHtmlParser(subHeading)}</Description>}
        <RegistrationForm
          onSuccess={onSuccess}
          formLegal={event?.register?.formLegal}
          formPart="1"
          formData={{
            fields: [
              {
                name: "FNAME",
                type: "text",
                placeholder: "First Name",
                span: "half",
                required: true,
              },
              {
                name: "LNAME",
                type: "text",
                placeholder: "Last Name",
                span: "half",
                required: true,
              },
              {
                name: "COMPANY",
                type: "text",
                placeholder: "Company Name",
                span: "full",
                required: false,
              },
              {
                name: "EMAIL",
                type: "text",
                placeholder: "Email Address",
                span: "full",
                required: true,
              },
            ],
          }}
        />
      </>
    );
  }

  return (
    <Layout
      registration
      showHeader={false}
      heroContent={false}
      noPadding
      showFooter
      style={{ alignItems: "flex-start" }}
    >
      <Registration>
        <ArtistImage as="section" id="artist-image"></ArtistImage>

        <Content>
          <FormContent>
            <Logo src={artistLogo} />
            {RegFormContent}
          </FormContent>
        </Content>
      </Registration>
    </Layout>
  );
});

const Logo = styled.img`
  width: 100%;
  max-width: 200px;
  height: auto;
  margin-bottom: 40px;
  @media only screen and ${(props) => props.theme.media.small} {
    margin-top: -40px;
    margin-bottom: 40px;
  }
`;

// ======== CONTENT / LEFT SIDE ======== //

const Registration = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  position: relative;
  width: 100%;

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    flex-direction: row;
    justify-content: flex-end;
    max-width: 1440px;
  }
`;

const Content = styled.div`
  margin: 0 auto;
  padding: 40px 16px 80px;
  width: 100%;
  height: 100%;
  max-width: 650px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    margin: 0;
    padding: 25px 50px;
    max-width: initial;
    width: 65%;
    min-height: 100vh;
    text-align: center;
  }
`;

const FormContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 450px;
  position: relative;
  z-index: 5;
`;

const Heading = styled.h2`
  font-size: 32px;
  font-family: "Arimo", sans-serif;
  font-weight: 400;
  line-height: 1.2;
  margin-bottom: 16px;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 3px;
  color: #cc8e3c;
  & > span {
    color: #d6d6d6;
    display: block;
    font-size: 60%;
    font-weight: 600;
    span {
      color: #ffffff;
    }
  }
  @media only screen and ${(props) => props.theme.media.small} {
    font-size: 25px;
  }
`;

const Description = styled.h3`
  font-size: 25px;
  font-family: "Arimo", sans-serif;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 16px;
  text-transform: uppercase;
  letter-spacing: 3px;
  color: #b3b3b3;
  text-align: center;
  @media only screen and ${(props) => props.theme.media.small} {
    font-size: 20px;
  }
`;

const SmallCountdown = styled.div`
  width: 100%;
  margin-bottom: 40px;

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    margin-bottom: 56px;
  }
`;

// ======== ARTIST IMAGE / RIGHT SIDE ======== //

const ArtistImage = styled.div`
  align-items: flex-start;
  mask-image: linear-gradient(
    180deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 1) 50%,
    rgba(0, 0, 0, 0) 100%
  );
  background-image: url(${artistImage});
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: ${(9 / 16) * 100}%;
  position: relative;
  background-position: center 20%;
  width: 100%;
  z-index: 2;

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    background: url(${artistImage});
    background-position: bottom left;
    background-size: contain;
    background-repeat: no-repeat;
    mask-image: none;
    padding-top: 0;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 705px;
    height: 100vh;
  }
`;
