export const carousel = {
  active: false,
  arrowFill: "#cccccc",
  autoplay: true,
  speed: 500,
  background: "transparent",
  imageWidth: "130px",
  padding: ["60px 0 0 0", "80px 0 0 0"],
  slideHeight: "174px",
  slideFill: "#eeeeee",
  slidesToShow: [1, 2, 3, 3],
  slidesToScroll: [1, 1, 1, 1],
};
