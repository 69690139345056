export const postShow = {
  video: {
    background: "#1e1e1e",
    padding: "50%",
  },
  // optional
  overlayImage: {
    marginTop: ["24px", "65px"],
    width: ["180px", "260px"],
  },
  // Text Elements
  overlayHeading: {
    font: "'pepsifizz-black', sans-serif",
    color: "#ffffff",
    fontWeight: 300,
    fontSize: ["42px", "120px"],
    lineHeight: ["42px", "120px"],
    marginBottom: ["24px", "24px"],
    textTransform: "uppercase",
  },
  overlayBody: {
    font: "'pepsifizz-black', sans-serif",
    color: "#ffffff",
    fontWeight: 700,
    fontSize: ["18px", "36px"],
    lineHeight: ["22px", "40px"],
    maxWidth: ["400px", "800px"],
  },
};
