export const reminder = {
  background: ["#ffffff", "#ffffff"],
  heading: {
    font: "'Helvetica', 'Arial', sans-serif",
    color: "#0065C3",
    fontSize: ["24px", "32px"],
    lineHeight: ["26px", "42px"],
    textAlign: ["center", "center"],
    textTransform: "uppercase",
    maxWidth: ["100%", "450px"],
  },
  card: {
    background: "#ffffff",
    border: "1px solid #A6AEB2",
    padding: ["40px 24px 104px 24px", "40px"],
  },
  subHeading: {
    font: "'Helvetica', 'Arial',, sans-serif",
    color: "#222222",
    fontSize: ["17px", "25px"],
    fontWeight: 400,
    lineHeight: ["22px", "32px"],
  },
  description: {
    font: "'Helvetica', 'Arial', sans-serif",
    color: "#222222",
    fontSize: ["12px", "17px"],
    fontWeight: 300,
    lineHeight: ["18px", "22px"],
    margin: ["0 0 16px 0", "0 0 24px 0"],
    textAlign: ["left", "left"],
    textTransform: "uppercase",
  },
  label: {
    font: "'Helvetica', 'Arial', sans-serif",
    color: "#222222",
    fontSize: ["15px", "15px"],
    fontWeight: 400,
    lineHeight: ["22px", "22px"],
  },
  input: {
    background: "#ffffff",
    border: "1px solid #A6AEB2",
    borderRadius: 0,
    font: "'Roboto', sans-serif",
    color: "#222222",
    fontSize: ["15px", "15px"],
    fontWeight: 400,
    height: "40px",
    lineHeight: ["22px", "22px"],
    textAlign: ["left", "left"],
    placeholder: {
      font: "'Roboto', sans-serif",
      color: "#222222",
      fontSize: ["15px", "15px"],
      fontWeight: 400,
      lineHeight: ["22px", "22px"],
      textAlign: ["left", "left"],
    },
  },
  footnote: {
    font: "'Helvetica', 'Arial', sans-serif",
    color: "#222222",
    fontSize: ["11px", "11px"],
    fontWeight: 400,
    lineHeight: ["14px", "14px"],
  },
  button: {
    color: "#0065C3",
    fill: "transparent",
    font: "'Helvetica', 'Arial', sans-serif",
    border: "3px solid #0065C3",
    borderRadius: "43px",
    fontSize: ["17px", "24px"],
    lineHeight: ["22px", "28px"],
    fontWeight: 400,
    height: ["40px", "60px"],
    textTransform: "uppercase",
    margin: ["24px 0 0 0", "40px 0 0 0"],
    colorHover: "#ffffff",
    borderHover: "3px solid #0065C3",
    fillHover: "#0065C3",
  },
};
