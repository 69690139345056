const brand = {
  light: "Source Sans Pro",
  regular: "Source Sans Pro",
  bold: "Source Sans Pro",
};

const fallbacks = {
  default: "Roboto, Helvetica, sans-serif, FordEmoji",
  header: "Montserrat, sans-serif",
};

export const fonts = {
  brand,
  default: `${brand.regular}, ${fallbacks.default}`,
  header: `${brand.bold}, ${fallbacks.header}`,
};
