import React, { useContext } from "react";
import styled from "styled-components";
import ReactHtmlParser from "react-html-parser";
import analytics from "services/analytics";
import { EventContext } from "Components";

export const Footer = ({ showFooter, registration }) => {
  const { event } = useContext(EventContext);

  const api = process.env.REACT_APP_FIRE_BASE_STORAGE_API;
  const storageDirectory = event.storageDirectory;

  let headerLogo = `${api}/o/${event?.logos?.header?.path}?alt=media`;
  let footerLogo = `${api}/o/${event?.logos?.footer?.path}?alt=media`;
  let headerBackground = `${api}/o/${storageDirectory}%2F${event?.backgrounds?.header}?alt=media`;

  const footerLogoLink = event.logos.footer.link;
  const menu = event.menus.footer;

  const logoClickHandler = () => {
    analytics.fireEvent({
      category: "External Link",
      action: "Footer Logo",
      label: footerLogoLink,
    });
  };

  const menuItemClickHandler = (link) => {
    analytics.fireEvent({
      category: "External Link",
      action: "Footer Menu",
      label: link,
    });
  };

  return (
    <>
      {showFooter && (
        <FooterWrapper
          as="footer"
          registration={registration}
          background={headerBackground}
        >
          <FooterContainer registration={registration}>
            <Link
              href={footerLogoLink}
              target="_blank"
              rel="noopener noreferrer"
              onClick={logoClickHandler}
            >
              <Logo src={footerLogo} />
            </Link>

            <Menu>
              {menu.map((item, idx) => {
                return (
                  <li key={idx}>
                    <a
                      href={item.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={() => menuItemClickHandler(item.link)}
                    >
                      {item.text}
                    </a>
                  </li>
                );
              })}
            </Menu>

            <MaxPolicy>{ReactHtmlParser(event.footer.maxPolicy)}</MaxPolicy>
          </FooterContainer>
        </FooterWrapper>
      )}
    </>
  );
};

const FooterWrapper = styled.footer`
  display: flex;
  flex-direction: row;
  color: ${(props) => (props.registration ? "#ffffff" : "#ffffff")};
  font-size: 10px;
  background-color: #ec1300;
  background-image: url(${(props) => props.background});
  background-position: center;
  background-size: cover;

  a,
  a:visited {
    color: ${(props) => (props.registration ? "#ffffff" : "#ffffff")};
  }
`;

const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  padding: 35px 15px;
  text-align: center;
`;

const Link = styled.a`
  margin-bottom: 15px;
`;

const Logo = styled.img`
  width: 100%;
  max-width: 250px;
`;

const Menu = styled.ul`
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 12px;

  a {
    display: block;
    padding: 5px;

    @media only screen and ${(props) => props.theme.maxMedia.small} {
      padding: 5px 10px;
    }
  }

  a,
  a:visited {
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
  }

  @media only screen and ${(props) => props.theme.maxMedia.small} {
    flex-direction: row;
    justify-content: center;
  }
`;

const MaxPolicy = styled.p``;
