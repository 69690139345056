import React from "react";
import styled from "styled-components";
import ReactHtmlParser from "react-html-parser";

export const MessageField = ({ field, error = {}, ...props }) => {
  return <Label {...props}>{ReactHtmlParser(field.message)}</Label>;
};

const Label = styled.p`
  color: #ffffff;
  font-size: 12px;
  line-height: 15px;
  margin-bottom: 8px;
  a,
  a:hover {
    color: #ffffff;
    text-decoration: underline;
  }
`;
