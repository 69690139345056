import React from "react";
import styled from "styled-components";
import ReactHtmlParser from "react-html-parser";

export const Main = ({ children, noPadding, subHeading, register }) => {
  return (
    !!children && (
      <MainWrapper id="main" noPadding={noPadding} register={register}>
        {subHeading && (
          <SubheadingContent>{ReactHtmlParser(subHeading)}</SubheadingContent>
        )}
        {children}
      </MainWrapper>
    )
  );
};

const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: ${(props) => (props.register ? "flex-start" : "center")};
  padding: ${(props) => (props.noPadding ? 0 : "15px")};
  width: 100%;
`;

const SubheadingContent = styled.h4`
  text-align: center;
  font-size: clamp(24px, 3vw, 32px);
  margin: 25px 15px 0;

  span {
    color: #f6231b;
    font-weight: 300;
  }
`;
