import React from "react";
import styled from "styled-components";
import { GridColumn, Modal } from "notes";
import { VideoPlayer } from "Components";

export const VideoModal = ({ open = false, setOpen, path, autoplay }) => {
  return (
    <Container
      open={open}
      setOpen={setOpen}
      size="full"
      style={{ background: "rgba(0,0,0,0.72)" }}
    >
      <VideoContainer>
        <VideoPlayer play={autoplay} path={path} />
      </VideoContainer>
    </Container>
  );
};

const Container = styled(Modal)`
  background-color: transparent;
  justify-content: center;
  align-items: center;
  & > svg {
    top: 65px;
    right: 65px;
    path {
      fill: #fff;
    }
  }
  @media only screen and ${(props) => props.theme.media.small} {
    & > svg {
      top: 45px;
      right: 24px;
    }
  }
`;

const VideoContainer = styled(GridColumn)`
  justify-content: center;
  position: relative;
  width: 100%;
  max-width: 1012px;
`;
