import React, { useContext } from "react";
import { EventContext } from "Components";
import analytics from "services/analytics";

export const Analytics = () => {
  const { event } = useContext(EventContext);
  const { tracking } = event;

  const gtmInit = {
    gtmId: process.env.REACT_APP_GTM_ID,
    dataLayer: {
      ...tracking,
    },
  };

  if (process.env.NODE_ENV === "production") {
    analytics.init(gtmInit);
  }

  return null;
};
