import React, { forwardRef } from "react";
import styled from "styled-components";

export const Input = forwardRef((props, ref) =>
  (({
    onChange,
    value,
    placeholder,
    error,
    disabled,
    InternalField = TextField,
    placeholderStyle,
    ...props
  }) => {
    const handleChange = (e) => {
      const {
        target: { value },
      } = e;
      return onChange(value, e);
    };
    return (
      <TextContainer error={error} disabled={disabled}>
        <Placeholder visible={value}>{placeholder}</Placeholder>
        <InternalField
          onChange={handleChange}
          value={value}
          error={error}
          disabled={disabled}
          {...props}
        />
      </TextContainer>
    );
  })({ ...props, ref })
);

const TextContainer = styled.div`
  background-color: ${(props) =>
    props.disabled ? "#A6AEB2" : "rgba(30,30,30,.6)"};
  display: block;
  position: relative;
  flex-grow: 1;
`;

const Placeholder = styled.div`
  align-items: center;
  display: ${(props) => (props.visible ? "none" : "flex")};
  justify-content: left;
  padding: 12px;
  position: absolute;
  width: 100%;
  z-index: 1;
  font-size: 12px;
  top: 0;
  bottom: 0;
  font-family: "Arimo", sans-serif;
  font-weight: 600;
  line-height: 1.2;
  /* text-transform: uppercase; */
  letter-spacing: 1.5px;
  color: #bcbcbc;

  @media only screen and ${(props) => props.theme.maxMedia.small} {
    font-size: 12px;
  }
`;

const TextField = styled.input`
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #cc8e3c;
  border-radius: 0;
  color: #c7c7c7;
  display: block;
  outline: 0;
  padding: 12px;
  position: relative;
  width: 100%;
  z-index: 2;
  -webkit-appearance: none;
  font-family: "Arimo", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.2;
  letter-spacing: 1.5px;
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    background-color: transparent;
    box-shadow: 0 0 0px 1000px #0f0f0f inset;
    -webkit-text-fill-color: #c7c7c7;
  }

  &:focus {
    border-bottom: 1px solid #d6d6d6;
  }
  &:disabled {
    border-bottom: 1px solid #cc8e3c;
  }

  ${(props) =>
    props.error &&
    `
    border-bottom: 2px solid #E45C52;

    &:focus {
      border-bottom: 2px solid #d6d6d6;
    }`};

  @media only screen and ${(props) => props.theme.maxMedia.small} {
    font-size: 16px;
  }
`;
