import React from "react";
import ReactHtmlParser from "react-html-parser";
import { Layout, BodySection, Container } from "../Layout";
import styled from "styled-components";
import { PostShowSurvey, Hero, Organization } from "Components";

export const PostShow = ({ event, token, onSuccess }) => {
  return (
    <Layout
      // header
      showHeader
      // hero
      heroContent={
        <HeroWrapper>
          <HeroLeft token={token} showSurvey={event.postShow?.showSurvey}>
            <Hero
              heroContent={
                <HeroSection token={token}>
                  <ThankYouHeader>
                    {ReactHtmlParser(
                      token
                        ? event?.postShow?.thankYouHeader
                        : event?.postShow?.thankYouHeader
                    )}
                  </ThankYouHeader>
                  <ThankYouBody>
                    {token
                      ? event?.postShow?.thankYouBody
                      : ReactHtmlParser(event?.postShow?.pastBody)}
                  </ThankYouBody>
                </HeroSection>
              }
            />
          </HeroLeft>

          {event.postShow?.showSurvey && token && (
            <HeroRight>
              <SurveyContainer>
                <PostShowSurvey
                  fields={event?.register?.fields}
                  token={token}
                />
              </SurveyContainer>
            </HeroRight>
          )}
        </HeroWrapper>
      }
      videoStyle={
        event?.show?.chatActive
          ? { position: "relative", width: "100%" }
          : undefined
      }
      // heading
      heading={event?.postShow?.heading}
      // main
      subHeading={event?.postShow?.subHeading}
      // noPadding
      // footer
      showFooter
    >
      <BodySection>
        <Container>
          <p>{ReactHtmlParser(event?.postShow?.body)}</p>
        </Container>
      </BodySection>
    </Layout>
  );
};

const HeroWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  background: #1e1e1e;
  position: relative;

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    flex-direction: row;
  }
`;

const HeroLeft = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${(props) =>
    props.token &&
    props.showSurvey &&
    `@media only screen and ${props.theme.maxMedia.medium} {
        margin-right: 392px;
      }`}
`;

const HeroRight = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  border-top: 1px solid #18436b;

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 392px;
    flex-shrink: 0;
  }
`;

const SurveyContainer = styled.div`
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const HeroSection = styled.div`
  text-align: center;
  padding: 15px;
`;

const ThankYouHeader = styled.h1`
  color: #d6d6d6;
  font-size: clamp(28px, 8vw, 98px);
  font-family: ${(props) => props.theme.fontStacks.header};
  line-height: 1;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 2vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  p {
    color: #b3b3b3;
    font-size: 25px;
    margin: 0;
    max-width: 450px;
  }
`;

const ThankYouBody = styled.h4`
  font-size: 4vw;
  color: #ffffff;
  margin: 0;
  text-transform: uppercase;

  a {
    color: #ffffff;
    text-decoration: none;
  }

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    font-size: 2.5vw;
  }
`;
