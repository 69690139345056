export const productAd = {
  active: false,
  background: "transparent",
  content: {
    margin: ["24px 0 0 0", "0 40px 0 0"],
    order: [2, 1],
  },
  heading: {
    color: "#ffffff",
    font: "'pepsifizz-black',sans-serif",
    fontSize: ["32px", "46px"],
    lineHeight: ["27px", "40px"],
    marginBottom: ["24px", "24px"],
    maxWidth: ["100%", "100%"],
  },
  body: {
    color: "#ffffff",
    font: "'pepsifizz-medium',sans-serif",
    fontSize: ["16px", "16px"],
    lineHeight: ["20px", "20px"],
    maxWidth: ["100%", "460px"],
  },
  image: {
    width: ["200px", "500px"],
    order: [1, 2],
  },
  padding: ["0 30px", "0 60px"],
  button: {
    color: "#ffffff",
    fill: "transparent",
    font: "'pepsifizz-medium', sans-serif",
    border: "3px solid #ffffff",
    borderRadius: "20px",
    fontSize: ["14px", "14px"],
    lineHeight: ["16px", "16px"],
    fontWeight: 400,
    height: ["40px", "40px"],
    textTransform: "uppercase",
    margin: ["16px 0 0 0", "16px 0 0 0"],
    colorHover: "#0065C3",
    borderHover: "3px solid #ffffff",
    fillHover: "#ffffff",
  },
};
