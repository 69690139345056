export const register = {
  altLogo: {
    active: true,
  },
  overlayImage: {
    active: true,
  },
  error: {
    color: "#ffffff",
    fontSize: "12px",
  },
};
