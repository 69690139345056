import React from "react";
import { DateMask, FieldMessage } from "Components";

export const DateField = ({ onChange, field, value, error = {}, ...props }) => {
  const handleText = (value) => {
    onChange({ [field.name]: value });
  };

  return (
    <>
      <DateMask
        date={value}
        onChange={(value) => handleText(value)}
        error={!!error.status}
        placeholder={field.placeholder ?? "Date of Birth (MM/DD/YYYY)"}
        {...props}
      />
      <FieldMessage active={!!error.status} content={error.message} />
    </>
  );
};
