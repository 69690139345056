import React from "react";
import styled from "styled-components";
import { GridColumn, GridRow, Label } from "notes";
import { Field } from "Components";

export const FormFields = ({
  fields,
  formPart,
  handleChange,
  errors,
  registration,
}) => {
  return (
    <Form>
      {fields &&
        fields
          .sort((a, b) => a.order - b.order)
          .filter((field) => {
            if (formPart === 1) {
              return field.formPart === "1";
            } else if (formPart === 2) {
              return field.formPart === "2";
            }
            return field;
          })
          .map((field, index) => {
            return (
              <FieldContainer fullSize={field.span === "full"} key={index}>
                {field.type !== "optIn" && !!field.label && (
                  <Label>{field.label}</Label>
                )}
                <Field
                  field={field}
                  fieldCheck={
                    !!field.requires ? registration[field.requires] : true
                  }
                  value={registration[field.name]}
                  onChange={(value) => handleChange(value, field.name)}
                  error={{
                    status: errors[field.name],
                    message: "This is a required field and cannot be blank.",
                  }}
                />
              </FieldContainer>
            );
          })}
    </Form>
  );
};

const Form = styled(GridRow)`
  align-items: flex-start;
  flex-wrap: wrap;
  margin-left: -8px;
  margin-right: -8px;
  width: 100%;
`;

const FieldContainer = styled(GridColumn)`
  flex: 0 1 ${(props) => (props.fullSize ? "100%" : "50%")};
  padding: 12px 8px 0 8px;
  width: ${(props) => (props.fullSize ? "100%" : "50%")};
`;
