export const preview = {
  artistImage: {
    backgroundPosition: "center center",
    backgroundSize: "cover",
  },
  overlay: {
    background:
      "linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 35%, rgba(0,0,0,0) 35%)",
  },
  // Text Elements
  overlayHeading: {
    color: "#ffffff",
    fontSize: ["20px", "48px"],
    lineHeight: ["22px", "54px"],
    fontWeight: 700,
    margin: ["0 0 16px 0", "0 0 60px 0"],
    textAlign: ["center", "center"],
    textTransform: "uppercase",
  },
};
