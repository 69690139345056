import React from "react";
import styled from "styled-components";
import ReactHtmlParser from "react-html-parser";
import { FieldMessage, Checkbox } from "Components";

export const OptIn = ({
  onChange,
  value,
  field,
  error = {},
  fieldCheck,
  ...props
}) => {
  const handleChange = (value) => {
    onChange({ [field.name]: value });
  };
  if (field.checked && value !== false) {
    onChange({ [field.name]: field.checked });
  }
  return (
    <>
      <Checkbox
        title={<Formatting>{ReactHtmlParser(field.label)}</Formatting>}
        checked={value}
        error={!!error.status}
        onChange={handleChange}
        disabled={!fieldCheck}
        {...props}
      />
      <FieldMessage active={!!error.status} content={error.message} />
    </>
  );
};

const Formatting = styled.div`
  color: #ffffff;
  font-size: 11px;
  line-height: 12px;
  text-transform: none;

  a,
  a:hover {
    text-decoration: underline;
  }
`;
