import React, { useContext } from "react";
import ReactHtmlParser from "react-html-parser";
import styled from "styled-components";
import { GridColumn, H2, P, ButtonPrimary } from "notes";
import { EventContext } from "Components";
import { responsiveCalc } from "Modules";

export const ProductAd = () => {
  const { event } = useContext(EventContext);
  let imagePath = `${process.env.REACT_APP_FIRE_BASE_STORAGE_API}/o/${event?.addons?.productAd?.image?.path}?alt=media`;
  const openLink = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };
  return (
    <Container yCenter>
      <Content>
        <Heading>{ReactHtmlParser(event?.addons?.productAd?.heading)}</Heading>
        <Body>{ReactHtmlParser(event?.addons?.productAd?.body)}</Body>
        {event?.addons?.productAd?.cta && (
          <Button onClick={() => openLink(event?.addons?.productAd?.cta?.path)}>
            {event?.addons?.productAd?.cta?.label}
          </Button>
        )}
      </Content>
      <ProductImage src={imagePath} />
    </Container>
  );
};

const Container = styled(GridColumn)`
  align-items: center;
  background: ${(props) => props.theme.productAd.background};
  padding: ${(props) => props.theme.productAd.padding[0]};
  text-align: center;
  @media only screen and ${(props) => props.theme.media.medium} {
    padding: ${(props) => props.theme.productAd.padding[1]};
    flex-direction: row;
    justify-content: space-between;
  }
`;

const Content = styled(GridColumn)`
  align-items: center;
  margin: ${(props) => props.theme.productAd.content.margin[0]};
  order: ${(props) => props.theme.productAd.content.order[0]};
  @media only screen and ${(props) => props.theme.media.medium} {
    align-items: flex-start;
    margin: ${(props) => props.theme.productAd.content.margin[1]};
    order: ${(props) => props.theme.productAd.content.order[1]};
  }
`;

const Heading = styled(H2)`
  color: ${(props) => props.theme.productAd.heading.color};
  font-family: ${(props) => props.theme.productAd.heading.font};
  font-size: ${(props) => props.theme.productAd.heading.fontSize[0]};
  line-height: ${(props) => props.theme.productAd.heading.lineHeight[0]};
  max-width: ${(props) => props.theme.productAd.heading.maxWidth[0]};
  margin-bottom: ${(props) => props.theme.productAd.heading.marginBottom[0]};
  @media only screen and ${(props) => props.theme.media.medium} {
    font-size: ${(props) =>
      responsiveCalc(props.theme.productAd.heading.fontSize[1])};
    line-height: ${(props) =>
      responsiveCalc(props.theme.productAd.heading.lineHeight[1])};
    margin-bottom: ${(props) =>
      responsiveCalc(props.theme.productAd.heading.marginBottom[1])};
    text-align: left;
  }
  @media only screen and ${(props) => props.theme.media.large} {
    font-size: ${(props) => props.theme.productAd.heading.fontSize[1]};
    line-height: ${(props) => props.theme.productAd.heading.lineHeight[1]};
    max-width: ${(props) => props.theme.productAd.heading.maxWidth[1]};
    margin-bottom: 24px;
  }
`;

const Body = styled(P)`
  color: ${(props) => props.theme.productAd.body.color};
  font-family: ${(props) => props.theme.productAd.body.font};
  font-size: ${(props) => props.theme.productAd.body.fontSize[0]};
  line-height: ${(props) => props.theme.productAd.body.lineHeight[0]};
  max-width: ${(props) => props.theme.productAd.body.maxWidth[0]};

  @media only screen and ${(props) => props.theme.media.medium} {
    font-size: ${(props) => props.theme.productAd.body.fontSize[1]};
    line-height: ${(props) => props.theme.productAd.body.lineHeight[1]};
    max-width: ${(props) => props.theme.productAd.body.maxWidth[1]};
    text-align: left;
  }
  @media only screen and (max-width: 600px) {
    margin-bottom: 16px;
  }
`;

const ProductImage = styled.img`
  display: block;
  width: 100%;
  max-width: ${(props) => props.theme.productAd.image.width[0]};
  order: ${(props) => props.theme.productAd.image.order[0]};
  @media only screen and ${(props) => props.theme.media.medium} {
    max-width: ${(props) =>
      responsiveCalc(props.theme.productAd.image.width[1])};
    order: ${(props) => props.theme.productAd.image.order[1]};
  }
  @media only screen and ${(props) => props.theme.media.large} {
    max-width: ${(props) => props.theme.productAd.image.width[1]};
  }
`;

const Button = styled(ButtonPrimary)`
  align-items: center;
  background-color: ${(props) => props.theme.productAd.button.fill};
  border: ${(props) => props.theme.productAd.button.border};
  border-radius: ${(props) => props.theme.productAd.button.borderRadius};
  box-shadow: none;
  color: ${(props) => props.theme.productAd.button.color};
  font-family: ${(props) => props.theme.productAd.button.font};
  font-size: ${(props) => props.theme.productAd.button.fontSize[0]};
  font-weight: ${(props) => props.theme.productAd.button.fontWeight};
  line-height: ${(props) => props.theme.productAd.button.lineHeight[0]};
  height: ${(props) => props.theme.productAd.button.height[0]};
  margin: ${(props) => props.theme.productAd.button.margin[0]};
  transition: all ease 0.3s;
  text-transform: ${(props) => props.theme.productAd.button.textTransform};
  @media only screen and ${(props) => props.theme.media.medium} {
    font-size: ${(props) => props.theme.productAd.button.fontSize[1]};
    line-height: ${(props) => props.theme.productAd.button.lineHeight[1]};
    height: ${(props) => props.theme.productAd.button.height[1]};
    margin: ${(props) => props.theme.productAd.button.margin[1]};
  }

  &:hover,
  &:focus {
    background-color: ${(props) => props.theme.productAd.button.fillHover};
    box-shadow: none;
    color: ${(props) => props.theme.productAd.button.colorHover};
    border: ${(props) => props.theme.productAd.button.borderHover};
  }
`;
