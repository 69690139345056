import React, { useContext } from "react";
import styled from "styled-components";
import { GridColumn, H3, H2, P } from "notes";
import { TextReminder, EventContext } from "Components";
import { Layout } from "../Root/Components/Layout";

export const Reminder = () => {
  const { event } = useContext(EventContext);

  return (
    <LayoutStyle showHeader noPadding showFooter>
      <Container yCenter>
        {!!event?.reminder && <Heading>{event?.reminder?.heading}</Heading>}
        <Card>
          <SubHeading>Text Message Reminder</SubHeading>
          <Description>
            Text me a reminder 30 minutes before the show with my ticket link
          </Description>
          <TextReminder showLabel />
        </Card>
      </Container>
    </LayoutStyle>
  );
};

const Card = styled(GridColumn)`
  background-color: #ffffff;
  border-radius: 8px;
  color: #222222;
  flex-grow: 1;
  padding: ${(props) => props.theme.reminder.card.padding[0]};
  position: relative;
  width: 100%;
  max-width: 472px;

  @media only screen and ${(props) => props.theme.media.medium} {
    border: ${(props) => props.theme.reminder.card.border};
    flex-grow: 0;
    margin: 24px auto 72px auto;
    padding: ${(props) => props.theme.reminder.card.padding[1]};
  }
`;

const SubHeading = styled(H3)`
  color: ${(props) => props.theme.reminder.subHeading.color};
  font-family: ${(props) => props.theme.reminder.subHeading.font};
  font-size: ${(props) => props.theme.reminder.subHeading.fontSize[0]};
  font-weight: ${(props) => props.theme.reminder.subHeading.fontWeight};
  line-height: ${(props) => props.theme.reminder.subHeading.lineHeight[0]};
  margin-bottom: 4px;
  @media only screen and ${(props) => props.theme.media.medium} {
    font-size: ${(props) => props.theme.reminder.subHeading.fontSize[1]};
    line-height: ${(props) => props.theme.reminder.subHeading.lineHeight[1]};
  }
`;

const Description = styled(P)`
  color: ${(props) => props.theme.reminder.description.color};
  font-family: ${(props) => props.theme.reminder.description.font};
  font-size: ${(props) => props.theme.reminder.description.fontSize[0]};
  font-weight: ${(props) => props.theme.reminder.description.fontWeight};
  line-height: ${(props) => props.theme.reminder.description.lineHeight[0]};
  margin: ${(props) => props.theme.reminder.description.margin[0]};
  text-align: ${(props) => props.theme.reminder.description.textAlign[0]};
  @media only screen and ${(props) => props.theme.media.medium} {
    font-size: ${(props) => props.theme.reminder.description.fontSize[1]};
    line-height: ${(props) => props.theme.reminder.description.lineHeight[1]};
    margin: ${(props) => props.theme.reminder.description.margin[1]};
    text-align: ${(props) => props.theme.reminder.description.textAlign[1]};
  }
`;

const LayoutStyle = styled(Layout)`
  background-color: ${(props) => props.theme.reminder.background[0]};
  flex-grow: 1;
  text-align: left;
  @media only screen and ${(props) => props.theme.media.medium} {
    background-color: ${(props) => props.theme.reminder.background[1]};
  }
`;

const Container = styled(GridColumn)`
  height: 100%;
  flex-grow: 1;
  width: 100%;
  margin: 0 auto;
  max-width: 472px;
  padding-top: 40px;
`;

const Heading = styled(H2)`
  color: ${(props) => props.theme.reminder.heading.color};
  font-family: ${(props) => props.theme.reminder.heading.font};
  font-size: ${(props) => props.theme.reminder.heading.fontSize[1]};
  line-height: ${(props) => props.theme.reminder.heading.lineHeight[1]};
  max-width: ${(props) => props.theme.reminder.heading.maxWidth[1]};
  padding: 0 40px;
  text-align: ${(props) => props.theme.reminder.heading.textAlign[1]};
  text-transform: ${(props) => props.theme.reminder.heading.textTransform};
  @media only screen and ${(props) => props.theme.media.small} {
    font-size: ${(props) => props.theme.reminder.heading.fontSize[0]};
    line-height: ${(props) => props.theme.reminder.heading.lineHeight[0]};
    max-width: ${(props) => props.theme.reminder.heading.maxWidth[0]};
    padding: 0 24px;
    text-align: ${(props) => props.theme.reminder.heading.textAlign[0]};
  }
`;
