import React from "react";
import styled from "styled-components";
import ReactHtmlParser from "react-html-parser";

export const Heading = ({ heading = false }) => {
  return (
    heading && (
      <HeadingWrapper id="heading">
        <HeadingContent>{ReactHtmlParser(heading)}</HeadingContent>
      </HeadingWrapper>
    )
  );
};

// ======== HEADING ======== //

const HeadingWrapper = styled.div`
  background: transparent;
  display: block;
`;

const HeadingContent = styled.h3`
  color: #d6d6d6;
  font-size: clamp(25px, 3vw, 40px);
  font-family: "Arimo", sans-serif;
  font-weight: 400;
  letter-spacing: 3px;
  line-height: 1.2;
  text-transform: uppercase;
  text-align: center;
  margin: 12px 0 0 0;
  padding: 0 8px 8px;
  border-bottom: 2px solid #cc8e3c;
`;
