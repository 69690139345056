import React from "react";
import styled from "styled-components";
import { Select as Select2 } from "notes";
import { FieldMessage } from "Components";
import { fonts } from "../../../theme/fonts";

export const Select = ({ onChange, value, field, error = {} }) => {
  let selected =
    value &&
    field.options.find((option) =>
      field.formPart === 2 ? option.id === value : option.text.includes(value)
    );

  const handleChange = (value) => {
    onChange({ [field.name]: value });
  };

  return (
    <>
      <StyledSelect
        placeholder={field.placeholder ?? "Select an option..."}
        selected={selected}
        options={field.options}
        error={!!error.status}
        onChange={(val) =>
          handleChange(field.formPart === 2 ? val.id : val.text)
        }
        style={{
          cursor: "default",
          padding: "12px 30px 12px 12px",
          height: "unset",
        }}
      />
      <FieldMessage active={!!error.status} content={error.message} />
    </>
  );
};

const StyledSelect = styled(Select2)`
  border-radius: 0;
  ${(props) => props.error && `background-color: #EDB8B4;`}

  span {
    font-weight: 300;
    line-height: 1.15;
  }

  ${(props) =>
    props.error &&
    `
    border: 2px solid #E45C52;

    &:focus {
      border-width: 1px;
    }`};

  @media only screen and ${(props) => props.theme.maxMedia.small} {
    span {
      font-size: 12px;
    }
  }
`;
