import React from "react";
import styled from "styled-components";
import { GridColumn, H3 } from "notes";
import ReactHtmlParser from "react-html-parser";
import { Video } from "Components";
import { Layout, BodySection } from "../Layout";

export const Preview = ({ event = {} }) => {
  const {
    heading,
    subHeading,
    overlayHeading,
    body,
    videoId,
    artistImage,
  } = event;
  return (
    <Layout
      heading={heading}
      subHeading={subHeading}
      heroContent={
        videoId ? (
          <Video id={videoId} />
        ) : (
          artistImage && (
            <ArtistImage
              background={`${process.env.REACT_APP_FIRE_BASE_STORAGE_API}/o/${artistImage?.path}?alt=media`}
              xCenter
              yEnd
            >
              {overlayHeading && (
                <Overlay yEnd>
                  <OverlayHeading>
                    {ReactHtmlParser(overlayHeading)}
                  </OverlayHeading>
                </Overlay>
              )}
            </ArtistImage>
          )
        )
      }
    >
      <BodySection>{ReactHtmlParser(body)}</BodySection>
    </Layout>
  );
};

const OverlayHeading = styled(H3)`
  color: ${(props) => props.theme.preview.overlayHeading.color};
  font-size: ${(props) => props.theme.preview.overlayHeading.fontSize[1]};
  font-weight: ${(props) => props.theme.preview.overlayHeading.fontWeight};
  line-height: ${(props) => props.theme.preview.overlayHeading.lineHeight[1]};
  margin: ${(props) => props.theme.preview.overlayHeading.margin[1]};
  text-align: ${(props) => props.theme.preview.overlayHeading.textAlign[1]};
  text-transform: ${(props) =>
    props.theme.preview.overlayHeading.textTransform};
  @media only screen and ${(props) => props.theme.maxMedia.small} {
    font-size: ${(props) => props.theme.preview.overlayHeading.fontSize[0]};
    line-height: ${(props) => props.theme.preview.overlayHeading.lineHeight[0]};
    margin: ${(props) => props.theme.preview.overlayHeading.margin[0]};
    text-align: ${(props) => props.theme.preview.overlayHeading.textAlign[1]};
  }
`;

const ArtistImage = styled(GridColumn)`
  background-image: url(${(props) => props.background});
  background-position: ${(props) =>
    props.theme.preview.artistImage.backgroundPosition};
  background-size: ${(props) => props.theme.preview.artistImage.backgroundSize};
  background-repeat: no-repeat;
  width: 100%;
  position: relative;
  z-index: 1;
`;

const Overlay = styled(GridColumn)`
  background: ${(props) => props.theme.preview.overlay.background};
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;
