import React, { createContext, useState, useEffect } from "react";
import { firebase } from "Components";

export const UserContext = createContext();
export const UserConsumer = UserContext.Consumer;
export const UserProvider = ({ children }) => {
  const [user, setUser] = useState();

  useEffect(() => {
    return firebase.auth().onAuthStateChanged(setUser);
  }, []);

  useEffect(() => {
    if (!user) {
      firebase.auth().signInAnonymously();
    }
  }, [user]);

  if (!user) {
    return null;
  }

  return (
    <UserContext.Provider value={{ user }}>{children}</UserContext.Provider>
  );
};
