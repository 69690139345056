import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { ButtonPrimary } from "notes";

export const AdminControls = ({ views, selectedView, setAdminView }) => {
  const [hidden, setHidden] = useState(false);
  const history = useHistory();
  if (hidden) {
    return null;
  }
  return (
    <ControlsContainer>
      <ButtonPrimary onClick={() => setHidden(true)}>Hide</ButtonPrimary>
      {views.map((view, idx) => (
        <ButtonPrimary
          disabled={view === selectedView}
          onClick={() => {
            setAdminView(view);
            // document.location.search = `view=${view}`;
            history.push(`?view=${view}`);
          }}
          key={idx}
        >
          {view}
        </ButtonPrimary>
      ))}
    </ControlsContainer>
  );
};

const ControlsContainer = styled.div`
  position: fixed;
  bottom: 20px;
  left: 20px;
  z-index: 1000;
`;
