import React, { useContext, useState, useEffect, useCallback } from "react";
import { useLocation } from "react-router-dom";
import { useCookies } from "react-cookie";
import { EventContext, TimeContext, firebase } from "Components";
import {
  PreShow,
  Show,
  PostShow,
  Register,
  AdminControls,
  Preview,
  PreCampaign,
} from "./Components";

const adminViews = ["register", "countdown", "show", "postshow"];

export const Root = () => {
  const { event } = useContext(EventContext);
  const { time } = useContext(TimeContext);
  const params = new URLSearchParams(useLocation().search);
  const [loading, setLoading] = useState(true);
  const [cookies, setCookie] = useCookies();
  const [adminView, setAdminView] = useState(null);

  const handleRegister = useCallback(
    (isAdmin, token, formPart = null) => {
      setCookie("formPart", parseInt(formPart) + 1, { maxAge: 31622400 });
      setCookie("token", token, { maxAge: 31622400 });

      if (isAdmin) {
        setCookie("admin", true, { maxAge: 31622400 });
        window.location.reload();
      }
    },
    [setCookie]
  );

  useEffect(() => {
    (async () => {
      if (params.get("token") && !cookies.token) {
        const verify = firebase.functions().httpsCallable("auth-verify");
        try {
          const verified = await verify({
            eventId: process.env.REACT_APP_EVENT_ID,
            token: params.get("token"),
          });
          if (verified?.data?.success) {
            handleRegister(verified?.data?.admin, params.get("token"), 1);
          }
        } catch (error) {
          console.error("token validation failed");
        }
      }
      setLoading(false);
    })();
  }, []);

  if (loading) return null;

  const register = {
    ...event.register,
    start: event.start,
    artist: event.artist,
    title: event.title,
    mastercampaignId: event.mastercampaignId,
    altLogo: event.logos?.header,
    overlayImage: event.logos?.footer,
    ...event,
  };

  const getAdminView = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const viewHistory = urlParams.get("view");

    switch (viewHistory || adminView) {
      case "precampaign":
        return <PreCampaign />;
      case "countdown":
        return (
          <Register
            onSuccess={handleRegister}
            event={register}
            cookies={cookies}
            adminView="countdown"
          />
        );
      case "register":
        return (
          <Register
            onSuccess={handleRegister}
            event={register}
            cookies={cookies}
          />
        );
      case "preshow":
        return <PreShow event={event} />;
      case "show":
        return <Show event={event} token={cookies.token} />;
      case "postshow":
        return <PostShow event={event} token={cookies.token} />;
      case "preview":
        return <Preview event={event.preview} />;
      default:
        return null;
    }
  };

  if (cookies?.admin) {
    return (
      <>
        <AdminControls
          views={adminViews}
          selectedView={adminView}
          setAdminView={setAdminView}
        />
        {getAdminView()}
      </>
    );
  }

  const preshowTransitionMinutes = event.preshowTransitionMinutes || 30;

  if (event.end < time && event.campaignEnd > time) {
    return <PostShow event={event} token={cookies.token} />;
  }

  if (cookies?.formPart >= 2) {
    // registered user
    if (
      event.start > time.plus({ minutes: preshowTransitionMinutes }) ||
      event.campaignEnd < time
    ) {
      return (
        <Register
          onSuccess={handleRegister}
          event={register}
          cookies={cookies}
        />
      );
    }

    return <Show event={event} token={cookies.token} />;
  }

  return (
    <Register onSuccess={handleRegister} event={register} cookies={cookies} />
  );
};
