import React, { useContext } from "react";
import styled, { ThemeContext } from "styled-components";
import { Carousel, ProductAd } from "Components";

export const AddOns = ({ noAddons }) => {
  const themeContext = useContext(ThemeContext);

  return (
    !noAddons &&
    (themeContext?.productAd?.active || themeContext?.carousel?.active) && (
      <AddOnsWrapper as="section">
        {themeContext?.productAd?.active && <ProductAd />}
        {themeContext?.carousel?.active && <Carousel />}
      </AddOnsWrapper>
    )
  );
};

const AddOnsWrapper = styled.div`
  background: #ffffff;
  padding: 50px 0;
  width: 100%;

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    padding: 100px 0;
  }
`;
