import React from "react";
import styled from "styled-components";
import { RadioLabel, RadioTitle, RadioContent } from "notes";

export const Checkbox = ({
  className,
  onChange,
  title,
  children,
  checked,
  disabled,
  hideCheck = false,
  error,
  ...props
}) => {
  const handleChange = (e) => {
    const {
      target: { checked },
    } = e;
    return onChange(checked, e);
  };
  return (
    <RadioContainer
      className={className}
      disabled={disabled}
      doubleLine={!!title}
    >
      <CheckboxContainer>
        <HiddenCheckbox
          checked={checked}
          onChange={handleChange}
          disabled={disabled}
          {...props}
        />
        {!hideCheck && (
          <StyledCheckbox checked={checked} doubleLine={!!title} error={error}>
            <Icon viewBox="0 0 8 6">
              <path
                d="M6.798 0L8 1.192 3.15 6 0 2.877l1.202-1.192L3.15 3.617z"
                fill="#FFF"
              />
            </Icon>
          </StyledCheckbox>
        )}
      </CheckboxContainer>
      <div>
        {!!title && <StyledRadioTitle>{title}</StyledRadioTitle>}
        <RadioContent doubleLine={!!title}>{children}</RadioContent>
      </div>
    </RadioContainer>
  );
};

const RadioContainer = styled(RadioLabel)`
  display: flex;
  align-items: start;
  padding: 0;
`;

const CheckboxContainer = styled.div`
  margin: 0 10px 0 0;
`;

const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
`;

const Icon = styled.svg`
  width: 8px;
  height: 6px;
`;

const StyledRadioTitle = styled(RadioTitle)`
  div,
  a {
    color: #626466;
  }
`;

const StyledCheckbox = styled.div`
  align-items: center;
  box-sizing: border-box;
  background: ${(props) => (props.checked ? "#ffffff" : "#ffffff")};
  border: 1px solid #626466;
  display: inline-flex;
  flex-shrink: 0;
  justify-content: center;
  transition: all 150ms;
  width: 24px;
  height: 24px;

  input[disabled] + & {
    background: #dcdcdc;
    border-color: #dcdcdc;
  }

  ${Icon} {
    width: 18px;
    height: 18px;
    visibility: ${(props) => (props.checked ? "visible" : "hidden")};
    path {
      fill: #676767;
    }
  }

  ${(props) =>
    props.error &&
    `
    border: 2px solid #E45C52;
    background: #EDB8B4;`}
`;
