export const preShow = {
  video: {
    background: "#1e1e1e",
    padding: "50%",
  },
  countdownLabel: {
    font: "'Helvetica', 'Arial', sans-serif",
    color: "#ffffff",
    fontSize: ["38px", "70px"],
    fontWeight: 700,
    lineHeight: ["42px", "74px"],
    marginBottom: ["10px", "40px"],
    textTransform: "uppercase",
  },
  incrementBorder: "4px solid #ffffff",
  increment: {
    font: "'Roboto', sans-serif",
    color: "#ffffff",
    fontSize: ["50px", "124px"],
    lineHeight: ["1.25", "1.25"],
    fontWeight: 300,
  },
  incrementLabel: {
    font: "'Roboto', sans-serif",
    color: "#ffffff",
    fontWeight: 700,
    fontSize: ["16px", "30px"],
    lineHeight: ["20px", "37px"],
  },
};
