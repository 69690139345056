import React from "react";
import styled from "styled-components";

export const Donation = ({ logo, cta, donationAmount, donateButtonText }) => {
  return (
    <DonationWrapper>
      <OrganizationLogo>
        <Logo src={logo} />
      </OrganizationLogo>
      <DonationBox>
        <CTA>{cta}</CTA>
        <DonationForm>
          ${donationAmount}
          <button>{donateButtonText}</button>
        </DonationForm>
      </DonationBox>
    </DonationWrapper>
  );
};

const DonationWrapper = styled.div`
  display: flex;
  padding: 15px;
`;

const OrganizationLogo = styled.div`
  background-color: #ffffff;
  padding: 15px 25px;
  display: flex;
  align-items: center;

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    padding: 10px 25px;
  }
`;

const Logo = styled.img`
  width: 100%;
  max-width: 150px;
  display: block;
`;

const DonationBox = styled.div`
  background-color: #4580dd;
  color: #ffffff;
  padding: 25px;
  flex: 1;
`;

const CTA = styled.div``;
const DonationForm = styled.div``;
