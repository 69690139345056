import React from "react";
import styled from "styled-components";
import { Checkbox, GridRow, GridColumn, P } from "notes";
import { FieldMessage } from "Components";

export const CheckboxField = ({ onChange, value = [], field, error = {} }) => {
  const selectedCheckboxes = value;

  const handleCheckbox = (checked, id) => {
    if (checked) {
      selectedCheckboxes.push(id);
    } else {
      const idx = selectedCheckboxes.findIndex((item) => item === id);
      selectedCheckboxes.splice(idx, 1);
    }
    onChange({ [field.name]: selectedCheckboxes });
  };

  return (
    <GridColumn>
      <FieldMessage active={!!error.status} content={error.message} />
      {field.options?.map(({ id, title, subtitle }) => (
        <CheckboxContainer
          key={id}
          checked={selectedCheckboxes.includes(id)}
          onChange={(checked) => handleCheckbox(checked, id)}
          title={!!subtitle && title}
        >
          <StyledLabel>{!!subtitle ? subtitle : title}</StyledLabel>
        </CheckboxContainer>
      ))}
    </GridColumn>
  );
};

const StyledLabel = styled.p`
  color: #ffffff;
  margin: 5px 0;
  font-size: 12px;
`;

const CheckboxContainer = styled(Checkbox)`
  margin: 0;
  // checkbox box
  div {
    background: #ffffff;
    border: 0;
  }
  // checkmark
  path {
    fill: #102b4e;
  }
`;
