import React from "react";
import styled from "styled-components";

export const CountdownSmall = ({ label, days, hours, minutes, seconds }) => {
  return (
    <>
      <CountdownContainer xCenter>
        <CountdownTitle>{label}</CountdownTitle>
        <GridRow>
          {days >= 1 && (
            <Outline xCenter>
              <Increment>
                {days < 10 ? "0" : ""}
                {days}
              </Increment>
              <Label>Day{days !== 1 ? "s" : ""}</Label>
            </Outline>
          )}
          <Outline xCenter>
            <Increment>
              {hours < 10 ? "0" : ""}
              {hours}
            </Increment>
            <Label>Hour{hours !== 1 ? "s" : ""}</Label>
          </Outline>
          <Outline xCenter>
            <Increment>{minutes}</Increment>
            <Label>Minute{minutes !== 1 ? "s" : ""}</Label>
          </Outline>
          {days < 1 && (
            <Outline xCenter>
              <Increment>{seconds}</Increment>
              <Label>Second{seconds !== 1 ? "s" : ""}</Label>
            </Outline>
          )}
        </GridRow>
      </CountdownContainer>
    </>
  );
};

const CountdownContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  /* @media only screen and ${(props) => props.theme.maxMedia.medium} {
    align-items: center;
    width: 200px;
  } */
`;

const CountdownTitle = styled.h3`
  color: #d6d6d6;
  font-family: "Arimo", sans-serif;
  font-size: 15px;
  font-weight: 700;
  line-height: 1.2;
  letter-spacing: 3px;
  margin-top: 24px;
  margin-bottom: 10px;
  text-align: center;
  text-transform: uppercase;
`;

const GridRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  max-width: 900px;
`;

const Increment = styled.p`
  color: #d6d6d6;
  font-weight: 700;
  font-size: 32px;
  font-family: "Arimo", sans-serif;
  letter-spacing: 3px;
  line-height: 32px;
  margin: 0;
  margin-bottom: 4px;

  /* @media only screen and ${(props) => props.theme.maxMedia.medium} {
    font-size: 24px;
    line-height: 1;
  } */
`;

const Label = styled.div`
  color: #b3b3b3;
  font-size: 12px;
  font-family: "Arimo", sans-serif;
  font-weight: 700;
  letter-spacing: 2px;
  text-transform: uppercase;

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    font-size: 12px;
    margin: 0;
  }
`;

const Outline = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(30, 30, 30, 0.6);
  color: #ffffff;
  padding: 15px;
  border-radius: 0px;
  width: 115px;
  height: 100px;
  & + & {
    margin-left: 12px;
  }

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    padding: 10px 5px;
  }
`;
