import React, { useState, useReducer, useContext } from "react";
import { useCookies } from "react-cookie";
import ReactHtmlParser from "react-html-parser";
import styled from "styled-components";
import { FormMessage, handleErrors, firebase, EventContext } from "Components";
import { reducer } from "Modules";
import analytics from "services/analytics";
import { FormFields } from "./FormFields";

export const RegistrationForm = ({
  formPart,
  formData,
  formLegal,
  onSuccess,
  submitButton,
}) => {
  const [registration, setRegistration] = useReducer(reducer, {});
  const [errors, setErrors] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const { event } = useContext(EventContext);
  const [cookies, setCookie] = useCookies();

  const { fields, ageGateMessage } = formData;
  const formHeading = event.register.formHeading;

  const handleChange = (value, name) => {
    setRegistration(value);
    delete errors[name];
    setErrors(errors);
  };

  const handleSubmit = async () => {
    let errorObject = handleErrors(fields, registration, formPart);
    if (!errorObject) {
      setSubmitting(true);
      try {
        firebase
          .firestore()
          .collection(`events/${process.env.REACT_APP_EVENT_ID}/lead_submits`)
          .add({
            ...registration,
            email: cookies.email ?? registration.EMAIL,
          })
          .then((ref) => {
            ref.onSnapshot((doc) => {
              const { token } = doc.data();
              if (token) {
                onSuccess(
                  false,
                  token,
                  formPart,
                  cookies.email ?? registration.EMAIL
                );
                setSubmitting(false);
                analytics.fireEvent({
                  category: `Form Submit`,
                  action: "Success",
                  label: window.location.path,
                  formData: {
                    ...registration,
                    email: cookies.email ?? registration.EMAIL,
                  },
                });
              }
            });
          });
      } catch (error) {
        setSubmitting(false);
      }
    } else {
      setErrors(errorObject);
    }
  };

  return (
    <>
      {formHeading && <FormTitle>{ReactHtmlParser(formHeading)}</FormTitle>}

      <FormFields
        fields={fields}
        formPart={formPart}
        handleChange={handleChange}
        errors={errors}
        registration={registration}
      />

      <FormMessage
        active={!!errors?.AGE_GATE}
        content={ageGateMessage}
        style={{ order: 6 }}
      />

      {formLegal && <FormLegal>{ReactHtmlParser(formLegal)}</FormLegal>}

      {submitting ? (
        <RegisterButton disabled>Loading</RegisterButton>
      ) : (
        <RegisterButton onClick={handleSubmit}>
          {submitButton ? submitButton : "Register"}
        </RegisterButton>
      )}
    </>
  );
};

const FormTitle = styled.h3`
  color: ${(props) => props.theme.palette.gray.primary};
  display: block;
  font-family: "Arimo", sans-serif;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 2px;
  line-height: 22px;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 40px;
  span {
    font-weight: 600;
  }
  @media only screen and ${(props) => props.theme.media.small} {
    font-size: 14px;
    letter-spacing: 1.5px;
  }
`;

const RegisterButton = styled.button`
  background-color: transparent;
  border: 1px solid #cc8e3c;
  color: #c7c7c7;
  padding: 10px 35px;
  cursor: pointer;
  text-decoration: none;
  text-transform: uppercase;
  margin-top: 40px;
  outline: 0;
  font-family: "Arimo", sans-serif;
  font-weight: 700;
  line-height: 1.2;
  letter-spacing: 1.5px;
  transition: all 0.2s ease;
  &:hover,
  &:focus {
    background-color: #cc8e3c;
    color: #000000;
  }
  @media only screen and ${(props) => props.theme.media.small} {
    width: calc(100% - 16px);
  }
`;

const FormLegal = styled.p`
  font-size: 10px;
  color: #626466;
  margin: 8px 0 0 0;
  padding: 0 8px;
  a {
    color: #626466;
  }
`;
