import React, { useContext } from "react";
import styled from "styled-components";
import analytics from "services/analytics";
import { EventContext } from "Components";
import artistLogo from "Images/artist-logo-desktop.png";

export const Header = ({ showHeader, registration }) => {
  const { event } = useContext(EventContext);

  const api = process.env.REACT_APP_FIRE_BASE_STORAGE_API;
  const storageDirectory = event.storageDirectory;

  let headerLogo = `${api}/o/${event?.logos?.header?.path}?alt=media`;
  let footerLogo = `${api}/o/${event?.logos?.footer?.path}?alt=media`;
  let headerBackground = `${api}/o/${storageDirectory}%2F${event?.backgrounds?.header}?alt=media`;

  const headerLogoLink = event.logos.header.link;

  const logoClickHandler = () => {
    analytics.fireEvent({
      category: "External Link",
      action: "Header Logo",
      label: headerLogoLink,
    });
  };

  return (
    <>
      {showHeader && (
        <HeaderContainer>
          <Logo src={artistLogo} />
        </HeaderContainer>
      )}
    </>
  );
};

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 12px 24px;
  @media only screen and ${(props) => props.theme.media.small} {
    position: absolute;
    padding: 0;
    top: 8px;
    left: 10px;
    z-index: 5;
  }
`;

const Link = styled.a``;

const Logo = styled.img`
  width: 150px;
  height: 52px;

  @media only screen and ${(props) => props.theme.media.small} {
    width: 70px;
    height: 24px;
  }
`;
