import React from "react";
import styled from "styled-components";
import { fonts } from "theme/fonts";
import { firebase } from "Components";
import { useDocumentData } from "react-firebase-hooks/firestore";

export const PoweredBy = ({ title, dealer, zipCode }) => {
  // if(zipCode) {
  //   const [dealer, loading, error] = useDocumentData(
  //     firebase.firestore().doc(`geo/ford/fdaf/${zipCode}`)
  //   );
  // }
  return (
    <PoweredByContainer>
      <PoweredByTitle>{title ? title : "Powered by"}</PoweredByTitle>
      <PoweredByDealer>
        {dealer ? dealer : `Your Local Ford Dealer`}
      </PoweredByDealer>
    </PoweredByContainer>
  );
};

const PoweredByContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  text-align: center;
`;

const PoweredByTitle = styled.p`
  color: #4580dd;
  font-size: 18px;
  margin: 0;
  margin-bottom: 5px;
`;

const PoweredByDealer = styled.p`
  font-size: 24px;
  margin: 0;
`;
